import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { ApolloProviderProps } from '@apollo/client/react/context'
import client from '@carrotcart/app/lib/apolloClient'

interface ApolloWebProviderProps
  extends Omit<ApolloProviderProps<any>, 'client'> {
  apolloCache?: NormalizedCacheObject
}

const ApolloWebProvider: React.FC<ApolloWebProviderProps> = ({
  children,
  apolloCache,
  ...props
}) => {
  // if apollo cache is passed in from server,
  // use it to initialize the client
  if (apolloCache) {
    client.cache.restore(apolloCache || {})
  }

  return (
    <ApolloProvider client={client} {...props}>
      {children}
    </ApolloProvider>
  )
}

export default ApolloWebProvider
