import React, { useEffect } from 'react'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'

const AuthError: React.FC = () => {
  const { authError, setAuthError } = useWebAppContext()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (authError) {
        setAuthError(undefined)
      }
    }, 8000)

    return () => {
      clearTimeout(timeout)
    }
  }, [authError, setAuthError])

  return authError ? (
    <div
      className="bg-yellow-700 rounded text-yellow-100 text-sm px-5 py-2"
      role="alert"
      dangerouslySetInnerHTML={{ __html: authError }}
    />
  ) : null
}

export default AuthError
