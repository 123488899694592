import { AppName } from '@carrotcart/common/lib/constants'
import {
  User_App_Version_Constraint,
  User_App_Version_Update_Column,
} from '@carrotcart/data/generated/enums'
import type {
  User_App_Version_Arr_Rel_Insert_Input,
  User_App_Version_Insert_Input,
} from '@carrotcart/data/generated/types'

interface NewAppVersionArgs {
  name: AppName
  version: string
  buildNumber?: number
  platform?: string
}

const newUserAppVersionInput = ({
  name,
  version,
  buildNumber,
  platform,
}: NewAppVersionArgs): User_App_Version_Arr_Rel_Insert_Input => {
  const newVersion: User_App_Version_Insert_Input = {
    name,
    version,
  }
  if (buildNumber) newVersion.build_number = buildNumber
  if (platform) newVersion.platform = platform

  return {
    data: [newVersion],
    on_conflict: {
      constraint:
        User_App_Version_Constraint.VersionUserIdNameVersionBuildNumberPlatformKey,
      update_columns: [User_App_Version_Update_Column.UpdatedAt],
    },
  }
}

export default newUserAppVersionInput
