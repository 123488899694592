import isAnonymousAuthUser from '@carrotcart/common/lib/isAnonymousAuthUser'
import { PartialAuthUser } from '@carrotcart/common/types'
import {
  ANONYMOUS_USER_DISPLAY_NAME,
  FALLBACK_DISPLAY_NAME,
} from '@carrotcart/common/lib/constants'

interface UserDetails {
  email: string | null | undefined
  phone: string | null | undefined
  picture: string | null | undefined
  authUserAnonymous: boolean
  name: string | null | undefined
}

const PRIVATE_RELAY_DOMAINS = new Set([
  'privaterelay.apple.com',
  'privaterelay.appleid.com',
])

export const isApplePrivateRelay = (
  email: string | null | undefined
): boolean => {
  return PRIVATE_RELAY_DOMAINS.has((email || '').split('@')[1])
}

const grabNameFromProviderData = (
  authUser: PartialAuthUser
): string | null | undefined => {
  if (authUser.displayName) {
    return authUser.displayName
  }

  return (authUser?.providerData || []).find((pd) => !!pd.displayName)
    ?.displayName
}

const providerDataToUserDetails = (
  authUser: PartialAuthUser,
  fallbackCredentialName?: string | undefined
): UserDetails => {
  const providerData = authUser.providerData?.[0]
  const anonymousDisplayName = ANONYMOUS_USER_DISPLAY_NAME
  const email = authUser?.email || providerData?.email
  const phone = authUser?.phoneNumber || providerData?.phoneNumber
  const authUserAnonymous = isAnonymousAuthUser(authUser)
  const providerDataDisplayName = grabNameFromProviderData(authUser)

  return {
    email,
    phone,
    picture: authUser?.photoURL || providerData?.photoURL,
    authUserAnonymous,
    name: authUserAnonymous
      ? anonymousDisplayName
      : providerDataDisplayName ||
        fallbackCredentialName ||
        FALLBACK_DISPLAY_NAME,
  }
}

export default providerDataToUserDetails
