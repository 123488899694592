import {
  UserDataFragment,
  RegisterUserDocument,
  RegisterUserMutation,
  RegisterUserMutationVariables,
  UpdateUserDetailsDocument,
  UpdateUserDetailsMutation,
  UpdateUserDetailsMutationVariables,
} from '@carrotcart/data/generated'
import { AppName } from '@carrotcart/common/lib/constants'
import omit from 'lodash/omit'
import client from '@carrotcart/app/lib/apolloClient'
import { User as AuthUser, AuthProvider } from 'firebase/auth'
import generateShortId from '@carrotcart/common/lib/generateShortId'
import providerDataToUserDetails from '@carrotcart/common/lib/providerDataToUserDetails'
import newUserAppVersionInput from '@carrotcart/client-common/lib/newUserAppVersionInput'
import logger from '@carrotcart/client-common/lib/logger/web'
import { UserMetadata } from '@carrotcart/common/types'

const IS_PROD_REGEX = /https?:\/\/(addtocarrot\.com|carrot\.link)/

interface maybeRegisterHelperResponse {
  success: boolean
  registeredUser?: UserDataFragment
  message?: string
}

// Update the existing user details when switch form Anonymous to Known (has email)
export async function updateExistingUser(
  authUser: AuthUser,
  currentProviders: AuthProvider[]
): Promise<maybeRegisterHelperResponse> {
  const { email, picture, name } = providerDataToUserDetails(authUser)

  // update user with name, email picture
  const variables: UpdateUserDetailsMutationVariables = {
    id: authUser.uid,
    email,
    name,
    picture,
    anonymous: false,
    // TODO should we reset the onboarding to false here to ensure
    // users complete onboarding as a full user not anonymous
  }

  const { errors } = await client.mutate<
    UpdateUserDetailsMutation,
    UpdateUserDetailsMutationVariables
  >({
    mutation: UpdateUserDetailsDocument,
    variables,
  })

  if (errors) {
    const errorMessage = errors.map((error) => error.message).join(', ')

    logger.error({
      message: `[Signup Update Error] ${errorMessage}`,
      user: { id: authUser.uid },
      providers: currentProviders,
      ...omit(variables, ['email', 'phone', 'phoneNumber']),
    })

    return {
      success: false,
      message:
        'Encountered an issue while signing in. The team has been notified.',
    }
  }

  return { success: true }
}

interface registerUserArgs {
  version: string | undefined
  authUser: AuthUser
  currentProviders: AuthProvider[]
  signupMetadata?: UserMetadata
  referrerUserId?: string
}

// Register a new user
export async function registerUser({
  version,
  authUser,
  currentProviders,
  signupMetadata,
  referrerUserId,
}: registerUserArgs): Promise<maybeRegisterHelperResponse> {
  const { email, phone, picture, authUserAnonymous, name } =
    providerDataToUserDetails(authUser)

  // Metadata logic.
  const metadata = signupMetadata ? signupMetadata : {}
  if (
    typeof window !== 'undefined' &&
    !IS_PROD_REGEX.test(window.location.origin) &&
    authUserAnonymous
  ) {
    /**
     * Set this metadata if we know we are in a development environment and the user is anonymous.
     * Will be used to sync with analytics. Using metadata for now. If we need this data to be
     * more easily queryable in trevor.io, we can promote to an actual column on the `public.user` table.
     */
    metadata.development = true
  }

  const shortId = generateShortId()
  const variables: RegisterUserMutationVariables = {
    short_id: shortId,
    email,
    phone,
    name,
    picture,
    anonymous: authUserAnonymous,
    with_extension_version: true,
    metadata,
    // TODO: Should we set onboarding to false for anonymous users, as can
    // direct them to web app from extension? may be better to have
    // a more specific signup cta in the extension somewhere for anon users
  }

  if (version) {
    variables.app_version = newUserAppVersionInput({
      name: AppName.Extension,
      version,
    })
  }

  if (referrerUserId) {
    variables.referrer_user_id = referrerUserId
  }

  const { data, errors } = await client.mutate<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >({
    mutation: RegisterUserDocument,
    variables,
  })

  const registeredUser = data?.user

  if (errors) {
    const errorMessage = errors.map((error) => error.message).join(', ')

    logger.error({
      message: `[Signup Error] ${errorMessage}`,
      user: { id: authUser.uid },
      providers: currentProviders,
      ...omit(variables, ['email']),
    })

    return {
      success: false,
      message:
        'Encountered an issue while signing up. The team has been notified.',
    }
  }

  return { success: true, registeredUser }
}
