import { useEffect, useState } from 'react'
import { EmailAuthProvider, PhoneAuthProvider } from 'firebase/auth'
import { UseAuthCallbackReturn } from '@carrotcart/app/hooks/useAuthCallback'
import useMediaQuery from '@carrotcart/app/hooks/useMediaQuery'
import tailwindConfig from '@carrotcart/app/tailwind.config'
import EmailLoginForm from './EmailLoginForm'
import PhoneLoginForm from './PhoneLoginForm'

interface Props {
  authCallback: UseAuthCallbackReturn['authCallback']
  signupFlow?: boolean
  defaultEmailMode?: boolean
  hideCancel?: boolean
}

const EmailPhoneProviderOption: React.FC<Props> = ({
  authCallback,
  signupFlow,
  defaultEmailMode,
  hideCancel,
}) => {
  const [useEmailPassword, setUseEmailPassword] = useState(defaultEmailMode)

  const matchesScreenSmDown = useMediaQuery(
    `(max-width: ${tailwindConfig.theme.extend.screens.sm})`
  )

  useEffect(() => {
    if (defaultEmailMode) return
    setUseEmailPassword(!matchesScreenSmDown)
  }, [matchesScreenSmDown, defaultEmailMode])

  return (
    <label className="block space-y-2">
      <div className="flex items-center justify-between px-4">
        <div className="font-semibold">
          Sign up with {useEmailPassword ? 'email' : 'phone'}
        </div>
        <button
          className="text-gray-400 text-sm"
          onClick={() => setUseEmailPassword(!useEmailPassword)}
        >
          Use {!useEmailPassword ? 'email' : 'phone'}
        </button>
      </div>

      {useEmailPassword ? (
        <EmailLoginForm
          signupFlow={signupFlow}
          hideCancel={hideCancel}
          callback={(email: string, password: string) =>
            authCallback({
              email,
              password,
              providerId: EmailAuthProvider.PROVIDER_ID,
            })(undefined)
          }
        />
      ) : (
        <PhoneLoginForm
          hideCancel={hideCancel}
          callback={({ confirmationResult, code }) =>
            authCallback({
              confirmationResult,
              code,
              providerId: PhoneAuthProvider.PROVIDER_ID,
            })(undefined)
          }
        />
      )}
    </label>
  )
}

export default EmailPhoneProviderOption
