import { useCallback, useEffect, useState } from 'react'
import {
  GoogleAuthProvider,
  User as AuthUser,
  ConfirmationResult,
} from 'firebase/auth'
import logger from '@carrotcart/client-common/lib/logger/web'
import { AnalyticsEventName } from '@carrotcart/common/lib/constants'
import { UserMetadata } from '@carrotcart/common/types'
import { getRoleFromIdTokenResult } from '@carrotcart/common/lib/hasuraClaimHelpers'
import useOnlineStatus from '@carrotcart/client-common/hooks/useOnlineStatus'
import {
  signInWithProvider,
  SupportedProviderId,
  AnonymousProvider,
  signInWithEmailPassword,
  signInWithPhone,
  CARROT_SIGNIN_EMAIL_KEY,
  createUserAuthData,
  SignInReturnValue,
} from '@carrotcart/client-common/lib/firebase'
import client from '@carrotcart/app/lib/apolloClient'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import providerDataToUserDetails from '@carrotcart/common/lib/providerDataToUserDetails'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSignupContext } from '@carrotcart/app/context/SignupProvider'
import {
  UserDataFragment,
  FindUserForAuthCheckDocument,
  FindUserForAuthCheckQuery,
  FindUserForAuthCheckQueryVariables,
} from '@carrotcart/data/generated'
import {
  updateExistingUser,
  registerUser,
} from '@carrotcart/app/lib/authHelper'
import { AuthCallback, SignupEventProperties } from '@carrotcart/app/types'
import isAnonymousAuthUser from '@carrotcart/common/lib/isAnonymousAuthUser'
import useImpersonateUser from './useImpersonateUser'

const ANONYMOUS_SIGNUP_ENABLED =
  process.env.NEXT_PUBLIC_ANONYMOUS_SIGNUP_ENABLED === 'true'

interface UseAuthCallbackProps {
  shouldCreateAnonUser?: boolean
  signupMetadata?: UserMetadata
  signupEventProperties?: SignupEventProperties
  callback?: AuthCallback
  automaticallyRegisterUser?: boolean
  referrerUserId?: string
}

interface AuthCallbackProps {
  providerId?: SupportedProviderId
  confirmationResult?: ConfirmationResult
  code?: string
  email?: string
  password?: string
  forceNewSignIn?: boolean
}

export interface UseAuthCallbackReturn {
  loading: boolean
  authCallback: ({
    providerId,
    confirmationResult,
    code,
    email,
    password,
    forceNewSignIn,
  }: AuthCallbackProps) =>
    | React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    | undefined
  maybeRegisterUser: (authUser: AuthUser) => Promise<{
    isNewUser: boolean
    registeredUser: UserDataFragment | null
  }>
}

const useAuthCallback = ({
  shouldCreateAnonUser,
  callback,
  signupMetadata,
  signupEventProperties,
  automaticallyRegisterUser = false,
  referrerUserId,
}: UseAuthCallbackProps): UseAuthCallbackReturn => {
  const {
    authError,
    currentUser,
    loadingApp,
    refetchCurrentUser,
    setAuthError,
    extensionState,
    shouldSignIntoExtension,
    signIntoExtension,
  } = useWebAppContext()
  const onLine = useOnlineStatus()
  const {
    authUser,
    loading: loadingAuth,
    clearOutPersistedAuthUserId,
  } = useAuthUser()
  const {
    manuallySignedOut,
    setManuallySignedOut,
    currentProviders,
    setCurrentProviders,
    currentProviderId,
    setCurrentProviderId,
  } = useSignupContext()
  const [creatingAnonUser, setCreatingAnonUser] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [authCallbackLoading, setAuthCallbackLoading] = useState(false)
  const isAnAnonymousAuthUser = isAnonymousAuthUser(authUser)
  const { updateImpersonationSignedOuts } = useImpersonateUser()

  const loading =
    authCallbackLoading || (authUser && shouldSignIntoExtension) || processing

  const maybeRegisterUser = useCallback(
    async (
      authUser: AuthUser
    ): Promise<{
      isNewUser: boolean
      registeredUser: UserDataFragment | null
    }> => {
      setProcessing(true)
      let isNewUser = false
      let registeredUser = null

      // we need to grab email from provider data as fallback
      const { authUserAnonymous } = providerDataToUserDetails(authUser)

      try {
        // look up the user in the database and if they don't exist,
        // create them
        const findUserResp = await client.query<
          FindUserForAuthCheckQuery,
          FindUserForAuthCheckQueryVariables
        >({
          query: FindUserForAuthCheckDocument,
          variables: {
            id: authUser.uid,
          },
          fetchPolicy: 'network-only',
        })

        registeredUser = findUserResp.data?.user

        if (registeredUser?.orphaned_at || registeredUser?.orphaned_by) {
          // stop here and throw error
          setAuthError(
            'Encountered an issue while signing in. The team has been notified.'
          )
          logger.error({
            message:
              '[Signup Error] FindUserForAuthCheck returned an orphaned user',
            'user.id': authUser.uid,
            'context.current_providers': currentProviders,
          })

          return
        }

        // if the user does exist this could be an anonymous user upgrading
        // check if databaseUser is anonymous & the authUser is not anonymous
        if (registeredUser?.anonymous && !authUserAnonymous) {
          const updateUserResp = await updateExistingUser(
            authUser,
            currentProviders
          )

          if (updateUserResp.success) {
            analytics.track(AnalyticsEventName.SignedIn, {
              id: authUser.uid,
              anonymous: registeredUser.anonymous,
              source: 'web',
              utm_source: 'web',
              utm_medium: 'internal',
              ...(signupEventProperties || {}),
            })

            // since we are manually processing the user, we need to
            // call `refetchCurrentUser` to trigger the AppProvider to
            // re-render
            await refetchCurrentUser()
          } else {
            setAuthError(updateUserResp?.message)
          }
        } else if (!registeredUser) {
          const registerUserResp = await registerUser({
            version: extensionState.version,
            authUser,
            currentProviders,
            signupMetadata,
            referrerUserId,
          })

          isNewUser = true
          registeredUser = registerUserResp?.registeredUser || null

          if (registerUserResp.success) {
            const eventName = registeredUser.anonymous
              ? AnalyticsEventName.SignedUpAnonymous
              : AnalyticsEventName.SignedUp

            analytics.track(eventName, {
              id: authUser.uid,
              anonymous: registeredUser.anonymous,
              source: 'web',
              utm_source: 'web',
              utm_medium: 'internal',
              ...(signupEventProperties || {}),
            })

            // since we are manually processing the user, we need to
            // call `refetchCurrentUser` to trigger the AppProvider to
            // re-render
            await refetchCurrentUser()
          } else {
            setAuthError(registerUserResp?.message)
          }
        }

        return { isNewUser, registeredUser }
      } finally {
        setProcessing(false)
      }
    },
    [
      setAuthError,
      currentProviders,
      signupEventProperties,
      refetchCurrentUser,
      extensionState.version,
      signupMetadata,
      referrerUserId,
    ]
  )

  const authCallback: ({
    providerId,
    confirmationResult,
    code,
    email,
    password,
    forceNewSignIn,
  }: AuthCallbackProps) =>
    | React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    | undefined = useCallback(
    ({
        providerId,
        confirmationResult,
        code,
        email,
        password,
        forceNewSignIn,
      }) =>
      async (event) => {
        event?.preventDefault()

        // If the user manaully signed out enable forceNewSignIn
        // when signing in with a provider that is not anonymous
        const manauallySigningIntoDifferentAccount =
          manuallySignedOut && providerId !== AnonymousProvider.PROVIDER_ID

        setAuthError(undefined)
        setAuthCallbackLoading(true)

        if (
          authUser &&
          currentProviders?.some(
            (authProvider) => authProvider.providerId === providerId
          ) &&
          !forceNewSignIn
        ) {
          logger.info({
            message: 'Attempting to log the user into the extension',
            'user.id': authUser.uid,
          })

          const alreadyAnonymous =
            isAnonymousAuthUser(authUser) && extensionState.signedInAnonymously

          if (shouldSignIntoExtension && !alreadyAnonymous) {
            logger.info({
              message:
                'Logging the user into the extension because we determined that they should be logged in',
              'user.id': authUser.uid,
            })

            await signIntoExtension(
              await createUserAuthData(authUser),
              isAnonymousAuthUser(authUser)
            )
          } else {
            logger.info({
              message: 'Not logging the user into the extension',
              'user.id': authUser.uid,
            })
          }
          setAuthCallbackLoading(false)
          return
        }

        try {
          clearOutPersistedAuthUserId()

          let signInReturn: SignInReturnValue = null

          if (confirmationResult && code) {
            signInReturn = await signInWithPhone({
              code,
              confirmationResult,
              shouldDeleteAnonymous: true,
              signupMetadata,
              logger,
            })
          } else {
            signInReturn = email
              ? await signInWithEmailPassword({
                  email,
                  password,
                  shouldDeleteAnonymous: true,
                  createUserIfNotFound: automaticallyRegisterUser,
                  signupMetadata,
                  logger,
                  callback: () => {
                    try {
                      window.localStorage.removeItem(CARROT_SIGNIN_EMAIL_KEY)
                    } catch (_err) {
                      // noop
                    }
                  },
                })
              : await signInWithProvider({
                  providerId,
                  logger,
                  signupMetadata,
                })
          }

          const { credential: userCreds, forceExtensionLogin } = signInReturn

          logger.debug(`should forceExtensionLogin? - ${forceExtensionLogin}`)

          if (userCreds) {
            const userAuthData = await createUserAuthData(userCreds.user)

            const tokenResult = userAuthData.tokenResult
            const role = getRoleFromIdTokenResult(tokenResult, 'user_admin')
            const isAdminRole = role === 'user_admin'

            if (isAdminRole) {
              updateImpersonationSignedOuts()
            }

            // once we have the user credentials, we can
            // reset the provider id and providers data so they
            // are no longer pointing to the anonymous provider
            setCurrentProviderId(providerId)
            setCurrentProviders(userCreds.user.providerData)

            const { isNewUser, registeredUser } = await maybeRegisterUser(
              userCreds.user
            )

            const miniOnboard =
              registeredUser && !registeredUser?.has_claimed_username_computed

            const alreadyAnonymous =
              isAnonymousAuthUser(userCreds.user) &&
              extensionState.signedInAnonymously

            if (
              shouldSignIntoExtension ||
              (extensionState.installed &&
                (forceNewSignIn || manauallySigningIntoDifferentAccount)) ||
              forceExtensionLogin
            ) {
              if (!alreadyAnonymous) {
                await signIntoExtension(
                  userAuthData,
                  isAnonymousAuthUser(userCreds.user)
                )
                setManuallySignedOut(false)
              }
            }

            if (callback) {
              await callback({
                newUser: isNewUser,
                userId: userCreds.user.uid,
                miniOnboard,
              })
            }
          }
        } catch (err: any) {
          setAuthError(err?.message)
          logger.error({
            message: `authCallback Error: ${err?.message}`,
            err,
            'user.id': authUser?.uid,
            'context.provider.id': providerId,
            'context.current_providers': currentProviders,
          })
        } finally {
          setAuthCallbackLoading(false)
        }
      },
    [
      manuallySignedOut,
      setAuthError,
      authUser,
      currentProviders,
      extensionState.signedInAnonymously,
      extensionState.installed,
      shouldSignIntoExtension,
      signIntoExtension,
      clearOutPersistedAuthUserId,
      signupMetadata,
      automaticallyRegisterUser,
      setCurrentProviderId,
      setCurrentProviders,
      maybeRegisterUser,
      callback,
      setManuallySignedOut,
      updateImpersonationSignedOuts,
    ]
  )

  // once we have an authUser, set current providers
  // and also provider id to the anonymous provider if is anonymous
  useEffect(() => {
    if (authUser) {
      setCurrentProviders(authUser.providerData)

      if (isAnAnonymousAuthUser) {
        setCurrentProviderId(AnonymousProvider.PROVIDER_ID)
      }
    }
  }, [
    authUser,
    isAnAnonymousAuthUser,
    setCurrentProviderId,
    setCurrentProviders,
  ])

  // For google-one tap, do a sanity check to make
  // sure we properly set the current provider info.
  // we cannot rely on the google one-tap callback,
  // as it doesn't always fire when the user is already
  // signed in with google
  useEffect(() => {
    if (currentUser) return
    if (loadingApp) return
    if (currentProviderId) return
    if (!authUser) return
    if (manuallySignedOut) return

    const providerId = authUser?.providerData?.[0]?.providerId

    if (providerId === GoogleAuthProvider.PROVIDER_ID) {
      setCurrentProviders(authUser.providerData)
      setCurrentProviderId(providerId)
    }
  }, [
    authUser,
    currentProviderId,
    currentUser,
    manuallySignedOut,
    loadingApp,
    setCurrentProviderId,
    setCurrentProviders,
  ])

  // Automatically sign the user into the extension if for whatever reason they are not
  useEffect(() => {
    if (!onLine) return
    if (!authUser) return
    if (!extensionState.installed) return
    if (extensionState.loading) return
    if (extensionState.signedIn) return
    if (processing) return
    if (authCallbackLoading) return
    ;(async () => {
      if (isAnonymousAuthUser(authUser) && extensionState.signedInAnonymously)
        return
      setProcessing(true)
      await signIntoExtension(
        await createUserAuthData(authUser),
        isAnonymousAuthUser(authUser)
      )
      setProcessing(false)
    })()
  }, [
    onLine,
    authUser,
    extensionState,
    processing,
    authCallbackLoading,
    setProcessing,
    signIntoExtension,
  ])

  // Create Anonymous User if extension is installed
  useEffect(() => {
    if (!ANONYMOUS_SIGNUP_ENABLED) return
    if (!shouldCreateAnonUser) return
    if (!onLine) return
    if (!extensionState.installed) return
    if (loadingAuth) return
    if (authUser) return
    if (manuallySignedOut) return
    if (extensionState.signedIn) return
    if (creatingAnonUser) return
    setCreatingAnonUser(true)

    authCallback({
      providerId: AnonymousProvider.PROVIDER_ID,
    })(undefined)
  }, [
    shouldCreateAnonUser,
    onLine,
    extensionState.installed,
    loadingAuth,
    authUser,
    manuallySignedOut,
    extensionState.signedIn,
    creatingAnonUser,
    authCallback,
  ])

  // Do a sanity check here to make sure that the user has been created in the database.
  // This could possibly happen if there was an issue when we initially tried to register
  // the user or if we are in a state of QA and removing users from the database for testing.
  useEffect(() => {
    if (!automaticallyRegisterUser) return
    if (!onLine) return
    if (!authUser) return
    if (!currentProviderId) return
    if (authError) return
    if (currentUser?.id) return
    if (processing) return
    if (authCallbackLoading) return
    if (creatingAnonUser) return
    ;(async () => {
      await maybeRegisterUser(authUser)
    })()
  }, [
    onLine,
    authUser,
    currentProviderId,
    authError,
    currentUser?.id,
    processing,
    authCallbackLoading,
    creatingAnonUser,
    maybeRegisterUser,
    automaticallyRegisterUser,
  ])

  return {
    loading,
    authCallback,
    maybeRegisterUser,
  }
}

export default useAuthCallback
