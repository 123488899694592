import { SVGProps, memo } from 'react'

const ReloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.1209 2.87918C13.5501 1.30834 11.3942 0.333344 9.0001 0.333344C4.21174 0.333344 0.344238 4.21168 0.344238 9C0.344238 13.7883 4.21174 17.6667 9.0001 17.6667C13.0409 17.6667 16.4101 14.9042 17.3742 11.1667H15.1209C14.2326 13.6908 11.8276 15.5 9.0001 15.5C5.41424 15.5 2.50007 12.5858 2.50007 9C2.50007 5.41418 5.41424 2.50001 9.0001 2.50001C10.7984 2.50001 12.4017 3.24751 13.5717 4.42834L10.0834 7.9167H17.6667V0.333344L15.1209 2.87918Z"
      fill="currentColor"
    />
  </svg>
)

const Memo = memo(ReloadIcon)
export default Memo
