import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { UserIcon, DeviceMobileIcon } from '@heroicons/react/solid'
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  EmailAuthProvider,
  UserInfo,
} from 'firebase/auth'
import {
  AppleProvider,
  SupportedProviderId,
} from '@carrotcart/client-common/lib/firebase'
// asset imports
import Loader from '@carrotcart/client-common/assets/images/loader.svg'
import GoogleLogo from '@carrotcart/client-common/assets/images/google.svg'
import FacebookAltLogo from '@carrotcart/client-common/assets/images/facebook-alt.svg'
import AppleLogo from '@carrotcart/client-common/assets/images/apple.svg'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'

interface ProviderButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  providerId?: SupportedProviderId
  source?: string
  loading?: boolean
}

const ProviderButton: React.FC<ProviderButtonProps> = ({
  children,
  className,
  onClick,
  providerId,
  source,
  loading,
}) => {
  const { authUser, loading: loadingAuthUser } = useAuthUser()
  const [logoComponent, setLogoComponent] = useState<React.ReactNode>(null)
  const [providerUserInfo, setProviderUserInfo] = useState<UserInfo>()

  const providerData = useMemo(
    () => authUser?.providerData || [],
    [authUser?.providerData]
  )

  const isGoogleAuth = providerId === GoogleAuthProvider.PROVIDER_ID

  const displayName =
    authUser?.displayName ||
    providerData.find((pd) => pd.displayName)?.displayName

  const photoURL =
    authUser?.photoURL || providerData.find((pd) => pd.photoURL)?.photoURL

  useEffect(() => {
    setProviderUserInfo(providerData.find((pd) => pd.providerId === providerId))
  }, [providerData, providerId])

  useEffect(() => {
    switch (providerId) {
      case GoogleAuthProvider.PROVIDER_ID:
        setLogoComponent(
          <div className="inline-flex">
            <GoogleLogo width={30} />
          </div>
        )
        break
      case FacebookAuthProvider.PROVIDER_ID:
        setLogoComponent(
          <div className="inline-flex text-[#C2C2BB] group-hover:text-[#3A5591] transition-colors">
            <FacebookAltLogo width={30} />
          </div>
        )
        break
      case AppleProvider.PROVIDER_ID:
        setLogoComponent(
          <div className="inline-flex text-[#C2C2BB] group-hover:text-black transition-colors">
            <AppleLogo width={30} />
          </div>
        )
        break
      case EmailAuthProvider.PROVIDER_ID:
        setLogoComponent(
          <div className="inline-flex text-[#C2C2BB] group-hover:text-black transition-colors">
            <DeviceMobileIcon width={30} />
          </div>
        )
        break
      default:
        break
    }
  }, [providerId])

  if (loadingAuthUser) return null

  return (
    <button
      onClick={(event) => {
        onClick && onClick(event)
        analytics.track('Clicked Signup Button', {
          providerId,
          source,
        })
      }}
      className={clsx(
        'group relative text-left border border-transparent flex w-full justify-center items-center rounded-full transition-all outline-none bg-container hover:bg-[#eaeadc]',
        className,
        {
          'max-w-[250px]': !providerUserInfo,
          'max-w-[300px] pointer-events-none': providerUserInfo,
        }
      )}
    >
      {!authUser || authUser?.isAnonymous || isGoogleAuth ? (
        <div className="relative w-[45px] h-[45px] flex-shrink-0 flex flex-col items-center justify-center">
          {providerUserInfo ? (
            photoURL ? (
              <div
                style={{
                  backgroundImage: `url(${photoURL})`,
                }}
                className="w-[38px] h-[38px] rounded-full bg-cover"
              />
            ) : (
              <div className="bg-gray-100 rounded-full p-2">
                <UserIcon className="w-full h-full text-gray-700" />
              </div>
            )
          ) : (
            logoComponent
          )}
        </div>
      ) : (
        <div className="w-[45px] h-[45px]" />
      )}

      {(children || providerUserInfo) && (
        <div
          className={clsx('w-full px-1 whitespace-nowrap', {
            'text-center leading-none': providerUserInfo,
          })}
        >
          {providerUserInfo ? (
            <div className="flex flex-col items-center">
              <div className="font-semibold truncate">{displayName}</div>
              <div className="opacity-70 text-sm truncate leading-tight">
                {providerUserInfo.email || providerUserInfo.phoneNumber}
              </div>
            </div>
          ) : (
            children
          )}
        </div>
      )}

      {authUser && (
        <div className="relative w-[45px] h-[45px] flex-shrink-0 flex flex-col items-center justify-center">
          {providerUserInfo && logoComponent}
          {loading && (
            <div
              className={clsx(
                'absolute inset-1 animate-spin  w-[42px] h-[42px]'
              )}
            >
              <Loader />
            </div>
          )}
        </div>
      )}
    </button>
  )
}

export default ProviderButton
