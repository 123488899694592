import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import type { ConfirmationResult } from 'firebase/auth'
import { parsePhoneNumber, getAsYouType } from 'awesome-phonenumber'
import { signInWithPhoneConfirmationResult } from '@carrotcart/client-common/lib/firebase'
import { useSignupContext } from '@carrotcart/app/context/SignupProvider'
import ProgressLinear from '@carrotcart/client-common/components/ProgressLinear'
import Button from '@carrotcart/client-common/components/Button'
import ReloadIcon from '@carrotcart/app/components/svg/ReloadIcon'

type SignInFormData = {
  phonenumber: string
  code: string
}
interface Props {
  hideCancel?: boolean
  callback: ({
    confirmationResult,
    code,
  }: {
    confirmationResult: ConfirmationResult
    code: string
  }) => void
  children?: ({
    inputHasFocus,
    confirmationStep,
  }: {
    inputHasFocus: boolean
    confirmationStep: boolean
  }) => React.ReactNode
}

const REGION_CODE = 'US'

const ayt = getAsYouType(REGION_CODE)

const PhoneLoginForm: React.FC<Props> = ({
  hideCancel,
  callback,
  children,
}) => {
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>()
  const [inputHasFocus, setInputHasFocus] = useState(false)
  const [processing, setProcessing] = useState(false)
  const { setEmailMode } = useSignupContext()

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    setFocus,
    formState: { errors },
  } = useForm<SignInFormData>({
    defaultValues: {
      phonenumber: '',
    },
  })

  const phonenumber = watch('phonenumber')

  const sendConfirmationCode = async (
    phonenumber: string
  ): Promise<ConfirmationResult> => {
    const parsedNumber = parsePhoneNumber(phonenumber, {
      regionCode: REGION_CODE,
    })
    const number = parsedNumber.number.e164
    const confirmationResult = await signInWithPhoneConfirmationResult(number)

    setConfirmationResult(confirmationResult)

    return confirmationResult
  }

  const onSubmit = async (data: SignInFormData) => {
    const { phonenumber, code } = data

    setProcessing(true)

    if (confirmationResult && code) {
      callback({
        confirmationResult,
        code,
      })
    }

    if (phonenumber && !code) {
      await sendConfirmationCode(phonenumber)
    }

    setProcessing(false)
  }

  useEffect(() => {
    if (!confirmationResult) return

    const timeout = setTimeout(() => {
      setFocus('code')
    }, 500)

    return () => clearTimeout(timeout)
  }, [confirmationResult, setFocus])

  return (
    <div
      className={clsx('flex flex-col', {
        'flex-1': !inputHasFocus && !phonenumber,
      })}
    >
      <div className="flex-1 flex flex-col space-y-6">
        <form
          className={clsx('flex-1 flex w-full flex-col space-y-1')}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="relative">
            <div className="space-y-2">
              {!confirmationResult ? (
                <div className="relative">
                  <div className="absolute left-2 inset-y-0 flex items-center justify-center">
                    <div className="rounded-full w-[35px] h-[35px] bg-cover bg-no-repeat bg-[url('/images/us-flag.png')]" />
                  </div>
                  <input
                    className={clsx(
                      'form-input pl-[55px] text-lg rounded-full px-4 py-3 w-full outline-none focus:outline-none',
                      {
                        'text-red': errors.phonenumber,
                      }
                    )}
                    type="tel"
                    placeholder="000 000-0000"
                    disabled={processing}
                    {...register('phonenumber', {
                      required: true,
                    })}
                    onKeyUp={() => {
                      const formatted = ayt.reset(
                        phonenumber.replace(/\D/g, '')
                      )

                      setValue('phonenumber', formatted)
                    }}
                    onFocus={() => setInputHasFocus(true)}
                    onBlur={() => setInputHasFocus(false)}
                  />
                </div>
              ) : (
                <input
                  className={clsx(
                    'form-input rounded-full text-lg tracking-[0.5em] px-4 py-3 w-full outline-none focus:outline-none text-center',
                    {
                      'outline-red-500': errors.code,
                    }
                  )}
                  type="tel"
                  placeholder="00000"
                  disabled={processing}
                  {...register('code', {
                    required: true,
                    valueAsNumber: true,
                  })}
                  onFocus={() => setInputHasFocus(true)}
                  onBlur={() => setInputHasFocus(false)}
                />
              )}
            </div>
          </div>

          <div
            id="phoneRecaptcha"
            className="flex items-center justify-center"
          />

          {children ? (
            children({
              inputHasFocus: inputHasFocus || !!phonenumber,
              confirmationStep: !!confirmationResult,
            })
          ) : (
            <Button
              type="submit"
              size="large"
              variant="primary"
              disabled={processing}
            >
              Next
            </Button>
          )}

          {confirmationResult && (
            <div className="text-center pt-2">
              <button
                onClick={async () => {
                  const phonenumber = getValues('phonenumber')
                  await sendConfirmationCode(phonenumber)
                }}
                className="inline-flex items-center p-1 text-sm space-x-1 text-gray-400"
              >
                <ReloadIcon width={12} height={12} />
                <span>Didn’t get it? Resend verification code</span>
              </button>
            </div>
          )}

          {!hideCancel && (
            <div className={clsx('flex w-full items-center justify-center')}>
              <button
                className="text-gray-400 underline"
                onClick={() => {
                  setEmailMode(false)
                }}
              >
                cancel
              </button>
            </div>
          )}
        </form>
      </div>
      <div
        className={clsx(
          'absolute z-10 bottom-0 inset-x-0 rounded-b overflow-hidden'
        )}
      >
        <ProgressLinear visible={processing} />
      </div>
    </div>
  )
}

export default PhoneLoginForm
