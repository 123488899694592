import React from 'react'
import Link from 'next/link'

const SignupLegalNotice: React.FC = () => (
  <p className="pt-2 text-xs text-slate max-w-sm mx-auto">
    By continuing, you agree to Carrot&#39;s{' '}
    <Link
      href="/legal/terms"
      target="_blank"
      rel="noreferrer"
      className="underline hover:underline"
    >
      Terms of Service
    </Link>{' '}
    and acknowledge you&#39;ve read our{' '}
    <Link
      href="/legal/privacy"
      target="_blank"
      rel="noreferrer"
      className="underline hover:underline"
    >
      Privacy Policy
    </Link>
  </p>
)

export default SignupLegalNotice
