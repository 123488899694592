import React from 'react'
import clsx from 'clsx'

export type ButtonSize = 'default' | 'x-small' | 'small' | 'large'
export type ButtonVariant =
  | 'base'
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'muted'
  | 'danger'
  | 'cancel'
  | 'confirm'

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: ButtonSize
  variant?: ButtonVariant
  shadow?: boolean
}

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  disabled,
  size = 'default',
  variant = 'primary',
  shadow,
  ...rest
}) => (
  <button
    className={clsx(
      'button-base inline-flex border border-transparent items-center justify-center rounded-full transition-colors tracking-wide whitespace-nowrap',
      {
        shadow,
        'bg-alt text-black text-opacity-80 hover:text-opacity-100 hover:bg-cloud':
          variant === 'secondary',
        'text-gray hover:text-black hover:bg-gray-50 hover:border-gray-50':
          variant === 'cancel',
        'bg-skin-primary text-skin-a11y hover:bg-skin-primary-dark':
          variant === 'primary',
        'bg-white text-skin-primary !border-skin-primary hover:text-skin-primary-dark':
          variant === 'outline',
        'bg-orange-50 text-danger-text hover:bg-orange-100':
          variant === 'danger',
        'bg-chrome-light text-purple hover:bg-chrome': variant === 'muted',
        'bg-confirm text-white hover:brightness-110': variant === 'confirm',
        disabled,
      },
      {
        'px-5 py-2': size === 'default',
        'px-3 py-1.5 text-xs': size === 'x-small',
        'px-4 py-2 text-sm': size === 'small',
        'px-7 py-3 text-lg': size === 'large',
      },
      className
    )}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
)

export default Button
