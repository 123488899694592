import React, { HTMLAttributes, useEffect } from 'react'
import clsx from 'clsx'
import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth'
import {
  AppleProvider,
  SupportedProviderId,
} from '@carrotcart/client-common/lib/firebase'
import { UserMetadata } from '@carrotcart/common/types'
import ProviderButton from '@carrotcart/client-common/components/ProviderButton'
import AuthError from '@carrotcart/app/components/AuthError'
import { useWebAppContext } from '@carrotcart/app/context/WebAppProvider'
import { useSignupContext } from '@carrotcart/app/context/SignupProvider'
import useAuthCallback from '@carrotcart/app/hooks/useAuthCallback'
import IntlCTA from '@carrotcart/app/components/IntlCTA'
import SignupLegalNotice from '@carrotcart/app/components/SignupLegalNotice'
import { AuthCallback, SignupEventProperties } from '@carrotcart/app/types'
import EmailPhoneProviderOption from './EmailPhoneProviderOption'

interface ProviderLoginProps extends HTMLAttributes<HTMLDivElement> {
  referrerUserId?: string
  signupFlow?: boolean
  hideIntlCTA?: boolean
  hideLegal?: boolean
  defaultEmailMode?: boolean
  hideCancel?: boolean
  signupEventProperties?: SignupEventProperties
  shouldCreateAnonUser?: boolean
  providerId?: SupportedProviderId
  hideAccountSwitcher?: boolean
  signupMetadata?: UserMetadata
  callback?: AuthCallback
}

const ProviderLogin: React.FC<ProviderLoginProps> = ({
  children,
  className,
  signupFlow,
  hideIntlCTA,
  hideLegal,
  defaultEmailMode,
  hideCancel,
  callback,
  shouldCreateAnonUser,
  hideAccountSwitcher,
  signupMetadata,
  signupEventProperties,
  referrerUserId,
}) => {
  const { authUser, isAnAnonymousAuthUser, extensionState } = useWebAppContext()

  const {
    emailMode,
    setEmailMode,
    currentProviders,
    currentProviderId,
    signOut,
    isInstagramBrowser,
  } = useSignupContext()

  const { loading, authCallback } = useAuthCallback({
    callback,
    shouldCreateAnonUser,
    signupMetadata,
    signupEventProperties,
    automaticallyRegisterUser: signupFlow,
    referrerUserId,
  })

  // If we detect instagram browser, we'll automatically
  // switch to email mode b/c instagram doesn't support
  // third party auth.
  useEffect(() => {
    if (isInstagramBrowser) {
      setEmailMode(true)
    }
  }, [isInstagramBrowser, setEmailMode])

  return (
    <div className="space-y-4 max-w-[350px] mx-auto">
      <AuthError />
      <div className={clsx('text-center min-w-[320px]', className)}>
        {authUser && currentProviders?.length ? (
          <div className="flex flex-col items-center space-y-10">
            <ProviderButton
              key={currentProviders[0].providerId}
              source={signupEventProperties?.utm_campaign}
              providerId={currentProviders[0].providerId as SupportedProviderId}
            />
          </div>
        ) : (
          <div>
            <div
              className={clsx(
                'space-y-2 flex flex-col items-center overflow-hidden transition-[max-height]',
                {
                  'max-h-0': emailMode,
                  'max-h-[270px]': !emailMode,
                }
              )}
            >
              {!isInstagramBrowser && (
                <div className="w-full flex flex-col items-center">
                  <ProviderButton
                    className="order-2 md:order-1 mb-2"
                    source={signupEventProperties?.utm_campaign}
                    providerId={GoogleAuthProvider.PROVIDER_ID}
                    onClick={authCallback({
                      providerId: GoogleAuthProvider.PROVIDER_ID,
                    })}
                    loading={
                      loading &&
                      currentProviderId === GoogleAuthProvider.PROVIDER_ID
                    }
                  >
                    Continue with Google
                  </ProviderButton>
                  <ProviderButton
                    className="order-3 md:order-2 mb-2"
                    source={signupEventProperties?.utm_campaign}
                    providerId={AppleProvider.PROVIDER_ID}
                    onClick={authCallback({
                      providerId: AppleProvider.PROVIDER_ID,
                    })}
                    loading={
                      loading && currentProviderId === AppleProvider.PROVIDER_ID
                    }
                  >
                    Continue with Apple
                  </ProviderButton>
                  <ProviderButton
                    className="order-1 md:order-3 mb-2"
                    source={signupEventProperties?.utm_campaign}
                    providerId={EmailAuthProvider.PROVIDER_ID}
                    onClick={() => {
                      setEmailMode(true)
                    }}
                  >
                    Use phone / email
                  </ProviderButton>
                </div>
              )}
            </div>

            {emailMode && (
              <EmailPhoneProviderOption
                signupFlow={signupFlow}
                authCallback={authCallback}
                defaultEmailMode={defaultEmailMode}
                hideCancel={hideCancel}
              />
            )}
          </div>
        )}

        {authUser && !isAnAnonymousAuthUser && !hideAccountSwitcher && (
          <div className="text-sm text-gray-400 mt-3">
            Not the right account?
            <br />
            <a
              className="text-purple hover:text-purple-700 hover:underline cursor-pointer"
              onClick={() => {
                analytics.track('Sign Out', {
                  'user.id': authUser.uid,
                  currentProviderId,
                  extensionState,
                })

                signOut()
              }}
            >
              Sign in with a different one
            </a>
          </div>
        )}

        {!emailMode && children}

        {(!authUser || isAnAnonymousAuthUser) && (
          <div className={clsx('text-center')}>
            <div className="pt-8">
              {!hideIntlCTA && (
                <div className="pt-2">
                  <IntlCTA />
                </div>
              )}
              {!hideLegal && <SignupLegalNotice />}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProviderLogin
