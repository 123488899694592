import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { EmailAuthProvider } from 'firebase/auth'
import { sendPasswordReset } from '@carrotcart/client-common/lib/firebase'
import Button from '@carrotcart/client-common/components/Button'
import { useSignupContext } from '@carrotcart/app/context/SignupProvider'

type SignInFormData = {
  email: string
  password: string
}
interface Props {
  signupFlow?: boolean
  hideCancel?: boolean
  callback: (email: string, password: string) => void
}

const EmailLoginForm: React.FC<Props> = ({
  signupFlow,
  hideCancel,
  callback,
}) => {
  const [resetPasswordMode, setResetPasswordMode] = useState(false)
  const { emailMode, setEmailMode } = useSignupContext()

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm<SignInFormData>({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (data: SignInFormData) => {
    if (resetPasswordMode) {
      await sendPasswordReset({ email: data.email })
      window.alert('Check your email for a password reset link.')
      setResetPasswordMode(false)
      return
    }

    callback(data.email, data.password)
  }

  const triggerResetPassword = async () => {
    setResetPasswordMode(true)
  }

  useEffect(() => {
    if (emailMode) {
      setFocus('email')
    }
  }, [emailMode, setFocus])

  return (
    <div className="relative">
      <form
        className={clsx('flex w-full flex-col space-y-2')}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="relative">
          <div className="space-y-2">
            <input
              className={clsx(
                'form-input rounded-full px-4 py-3 w-full outline-none focus:outline-none',
                {
                  'outline-red-500': errors.email,
                }
              )}
              name="email"
              type="email"
              autoComplete="off"
              autoCorrect="off"
              placeholder="Email"
              {...register('email', {
                required: true,
              })}
            />
            {!resetPasswordMode && (
              <input
                className={clsx(
                  'form-input rounded-full px-4 py-3 w-full outline-none focus:outline-none',
                  {
                    'outline-red-500': errors.email,
                  }
                )}
                name="password"
                type="password"
                autoComplete="off"
                autoCorrect="off"
                placeholder={signupFlow ? 'Choose password' : 'Password'}
                {...register('password', {
                  required: true,
                })}
              />
            )}
          </div>
        </div>
        <Button
          type="submit"
          size="large"
          variant="primary"
          className={clsx('transition-opacity', {
            'lg:opacity-0 lg:pointer-events-none': !emailMode,
            'opacity-100': emailMode,
          })}
          onClick={() => {
            if (!resetPasswordMode) {
              analytics.track('Clicked Email Signup Button', {
                providerId: EmailAuthProvider.PROVIDER_ID,
              })
            }
          }}
        >
          {signupFlow ? 'Sign Up' : 'Submit'}
        </Button>

        <div
          className={clsx('flex w-full items-center', {
            'justify-center': signupFlow,
            'justify-between': !signupFlow,
          })}
        >
          {!hideCancel && (
            <button
              className="text-gray-400 underline"
              onClick={() => {
                if (resetPasswordMode) {
                  setResetPasswordMode(false)
                } else {
                  setEmailMode(false)
                }
              }}
            >
              cancel
            </button>
          )}
          {!signupFlow && (
            <button
              onClick={async (event) => {
                event.preventDefault()
                await triggerResetPassword()
              }}
              className={clsx('underline text-purple', {
                'flex-1': hideCancel,
              })}
            >
              Set new password
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default EmailLoginForm
